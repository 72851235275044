.container {
    display: grid;
    grid-template-columns: auto auto;
    height: 100vh;
}

.img_item {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #001721;
    box-shadow: 0px 0px 10px #0018a1;
}

.welcome {
    color: #44996d;
    font-size: 80px;
    padding: 120px 10px;
}

.img_item img {
    width: 60vw;
    height: 100vh;
}

.login {
    background-color: #0075a8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 40vw;
    gap: 30px;
}

.login div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: #000; */
    gap: 20px;
}

.login label {
    display: flex;
    width: min-content;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.login input {
    background: #ebebeb;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    padding: 10px;
    font-weight: 600;
}

.login p {
    font-weight: bold;
}

.login img {
    width: 20vw;
    height: 40vh;
}

.snackbar {
    display: flex;
    position: absolute;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        /* padding: 10px; */
        /* padding-top: 30px; */
    }

    .img_item {
        width: 100vw;
        height: 50vh;
    }

    .img_item img {
        width: 100vw;
        height: 50vh;
    }

    .login {
        width: 100vw;
        gap: 10px;
        padding-bottom: 50px;

    }

    .login img {
        width: 50vw;
        height: 50vw;
    }

    .login div {
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding: 0;
        padding-bottom: 30px;
    }
}