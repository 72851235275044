.container {
    padding-left: 11vw;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 30px;
    }
}