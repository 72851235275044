.container {
    padding-left: 11vw;
    padding-top: 30px;
    /* max-width: 100vw; */
    /* display: grid; */
    /* background: #000; */
}

.column {
    /* background: #000; */
    /* width: min-content; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 10vw;
    align-items: end;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 30px;
    }
}