.container {
    top: 0;
    right: 0;
    /* width: 88.3vw; */
    box-shadow: 5px 1px 10px #939393;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    /* padding-left: 11vw; */
    position: relative;
    /* position: fixed; */
    z-index: 1;
    padding-left: 11vw;
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: #2c9961;
}

.log_out {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: rgb(253, 222, 222);
    padding: 0 5px;
    border-radius: 8px;
}

.log_out p {
    color: red;
    font-weight: 500;
}

.log_out:hover {
    transform: scale(1.05);
    transition: 330ms ease-in-out;
    cursor: pointer;
    background-color: rgb(255, 191, 191);
}

.space_between {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 768px) {
    .container {
        width: 100vw;
        left: 0;
        padding: 0;
        max-width: 100vw;
        margin: 0;
    }

    .title {
        padding-left: 50px;
    }

    .log_out {
        margin: 10px;
    }
}