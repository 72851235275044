.container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 0 10px;
    padding-left: 11vw;
    padding-top: 30px;
    gap: 20px;
}

p {
    padding: 10px;
    font-weight: 300;
}

.user_container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    padding-left: 11vw;
    padding-top: 30px;
    gap: 20px;
}